import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "./slider.css";
import { FaTimesCircle } from "react-icons/fa";
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";

const slides = [
  {
    backgroundColor: 'https://заправка-принтера.бел/wp-content/uploads/2019/10/remont_printera-400x364.png',
    title: 'Ремонт и обслуживание МФУ и принтеров',
    description: 'Наши специалисты готовы помочь практически с любой неисправностью печатной техники. Задайте вопрос и наш менеджер свяжется с вами',
  },
  {
    backgroundColor: 'https://static.tildacdn.com/tild6539-6237-4430-a232-623932323634/photo.png',
    title: 'Заправка и восстановление картриджей',
    description: 'Знаем, как продлить жизнь вашим картриджам. Заправка и восстановление на профессиональном оборудовании. Качество и надежность.',
  },
  {
    backgroundColor: 'https://www.airkitchen.uk/wp-content/uploads/2017/05/caisse-tactile-complete-ak.png',
    title: 'Регистрация ККТ стала проще!',
    description: 'Оставьте заявку на регистрацию ККТ у нас и получите полный комплекс услуг по подготовке и подаче документов в ФНС. Мы гарантируем быстрое и беспроблемное прохождение регистрации вашей ККТ.',
  },
];

const delay = 10000;

const Slider = () => {

  const [currentSlide, setCurrentSlide] = useState(0);

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const [isSuccess, setIsSuccess] = useState(false);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  // Состояния для управления видимостью модальных окон
  const [showCallbackModal, setShowCallbackModal] = useState(false);
  const [showEngineerCourierModal, setShowEngineerCourierModal] = useState(false);
  const [serviceType, setServiceType] = useState('engineer'); // 'engineer' или 'courier'

  // Функции для открытия и закрытия модальных окон
  const openCallbackModal = () => setShowCallbackModal(true);
  const closeCallbackModal = () => setShowCallbackModal(false);
  const openEngineerCourierModal = () => setShowEngineerCourierModal(true);
  const closeEngineerCourierModal = () => setShowEngineerCourierModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [opisanie, setOpisanie] = useState('');

  const todays = new Date();

  const today = todays.toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const time = todays.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: '2-digit'
  });

  const handleSave = async (phone, name, opisanie) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`https://lk.eq74.ru/api/zayssite/newzayssite`, { phone, name, opisanie, data: today, time: time });
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        setShowCallbackModal(false); // Закрыть модальное окно после успешной отправки
      }, 2000); // Задержка перед закрытием модального окна
    } catch (error) {
      console.error('Error saving profile:', error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 5000); // Интервал в миллисекундах для автоматического переключения слайдов

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = (event, action) => {
    event.preventDefault(); // Предотвращаем перезагрузку страницы
    if (action === 'callback') {
      openCallbackModal();
    } else if (action === 'engineerCourier') {
      openEngineerCourierModal();
    }
  };

  const navigate = useNavigate();

  return (
    <div className='flex relative min-h-40 w-full items-center justify-center place-content-between flex-wrap bg-gray-700 gap-10 p-10'>
      {slides.map((slide, index) => (
        <form key={index} className={`relative flex-1 items-center justify-center flex-row flex-wrap gap-10 ${index === currentSlide ? 'flex' : 'hidden'}`}>
          <div className='flex relative  items-center justify-center h-60 w-60'>
            <img src={slide.backgroundColor} className='object-contain' />
          </div>
          <div className='flex relative h-60 max-w-full flex-1 flex-col gap-10 flex-wrap'>
            <span className='flex relative items-center justify-center text-center min-h-10 max-w-full flex-col text-white text-3xl flex-wrap'>
              {slide.title}
            </span>
            <span className='flex relative items-center justify-center text-center min-h-20 max-w-full flex-1 flex-col text-white text-xl flex-wrap'>
              {slide.description}
            </span>
          </div>
        </form>
      ))}
      <form className="relative items-center justify-center h-60 w-60 flex flex-col">
        <div className='flex relative flex-col gap-2'>
          <button
            onClick={(e) => handleButtonClick(e, 'callback')}
            disabled={isLoading}
            type="button"
            className="inline-block h-12 rounded border-2 border-slate-200 text-slate-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700"
          >
            Обратный звонок
          </button>
          <button
            onClick={(e) => handleButtonClick(e, 'engineerCourier')}
            disabled={isLoading}
            type="button"
            className="inline-block h-12 rounded border-2 border-slate-200 text-slate-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700"
          >
            Вызов инженера/курьера
          </button>
          <button
            type="button"
            className="inline-block h-12 rounded border-2 border-slate-200 text-slate-200 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:scale-110 hover:bg-neutral-900 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700"
            onClick={() => window.location.href = 'https://lk.eq74.ru'}
            disabled={isLoading}
          >
            Личный кабинет
          </button>
        </div>
      </form>

      {/* Модальное окно "Обратный звонок" */}
      {showCallbackModal && (
        <div className="modal-overlay">
          <div className="flex relative flex-col w-96 min-h-96 bg-slate-50 gap-4 p-5 rounded-lg justify-center items-center content-center text-center">
            {isLoading ? (
              <div className='flex relative w-full h-full content-center justify-center text-center items-center'>
                <ReactLoading
                  type="spokes"
                  color="#000"
                  height={"30%"}
                  width={"30%"}
                />
              </div>
            ) : isSuccess ? (
              <div>Запрос успешно отправлен!</div>
            ) : (
              <>
                <h2>Заполните форму, и наш специалист свяжется с вами</h2>
                <button className="modal-close-btn" onClick={closeCallbackModal}><FaTimesCircle /></button>
                <input className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2' type="text" placeholder="Имя" name="name" onChange={e => setName(e.target.value)} />
                <InputMask className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2' mask="+7 (999) 999-99-99" maskChar="_" type="text" placeholder="Телефон" name="phone" onChange={e => setPhone(e.target.value)} />
                <textarea className='flex relative w-full min-h-32 p-2 rounded-lg border-slate-900 border-2' type="text" name="opisanie" placeholder="Краткое описание вопроса" onChange={e => setOpisanie(e.target.value)} />
                <button className='btn-modal' onClick={() => handleSave(phone, name, opisanie)} disabled={isLoading}>Отправить</button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Модальное окно "Вызов инженера/курьера" */}
      {showEngineerCourierModal && (
        <div className="modal-overlay">
          <div className="flex relative flex-col w-96 min-h-96 bg-slate-50 gap-4 p-5 rounded-lg justify-center items-center content-center text-center">
            {isLoading ? (
              <div className='flex relative w-full h-full content-center justify-center text-center items-center'>
                <ReactLoading
                  type="spokes"
                  color="#000"
                  height={"30%"}
                  width={"30%"}
                />
              </div>
            ) : isSuccess ? (
              <div>Запрос успешно отправлен!</div>
            ) : (
              <>
                <h3>Заполните форму для заказа курьера/инженера</h3>
                <button className="modal-close-btn" onClick={closeEngineerCourierModal}><FaTimesCircle /></button>
                {/* Выбор между инженером и курьером */}
                <div className='vibor-type'>
                  <span>Инженер</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={serviceType === 'courier'}
                      onChange={() => setServiceType(serviceType === 'engineer' ? 'courier' : 'engineer')}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>Курьер</span>
                </div>
                {serviceType === 'engineer' ? (
                  <EngineerForm onSave={handleSave} setIsSuccess={setIsSuccess} setIsLoading={setIsLoading} setShowEngineerCourierModal={setShowEngineerCourierModal} />
                ) : (
                  <CourierForm onSave={handleSave} setIsSuccess={setIsSuccess} setIsLoading={setIsLoading} setShowEngineerCourierModal={setShowEngineerCourierModal} />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// Компонент формы для инженера
const EngineerForm = ({ onSave, setIsSuccess, setIsLoading, setShowEngineerCourierModal }) => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [model, setModel] = useState('');
  const [opisanie, setOpisanie] = useState('');
  const [adress, setAdress] = useState('');

  const todays = new Date();

  const today = todays.toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const handleSaveEng = async (phone, name, model, adress, opisanie) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`https://lk.eq74.ru/api/zayssitecur/newzayssitecur`, { type: 'engineer', phone, name, model, adress, opisanie, data: today });
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        setShowEngineerCourierModal(false); // Закрыть модальное окно после успешной отправки
      }, 2000); // Задержка перед закрытием модального окна

    } catch (error) {
      console.error('Error saving profile:', error);

    }
    setIsLoading(false);
  }

  // Здесь могут быть дополнительные состояния и логика для формы инженера
  return (
    <div className="flex relative flex-col w-full min-h-full bg-slate-50 gap-4 p-5 rounded-lg justify-center items-center content-center text-center">
      <input
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        placeholder="Имя"
        name="name"
        onChange={e => setName(e.target.value)}
      />
      <InputMask
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        mask="+7 (999) 999-99-99"
        maskChar="_"
        type="text"
        placeholder="Телефон"
        name="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
      />
      <input
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        placeholder="Модель устройства"
        name="model"
        onChange={e => setModel(e.target.value)}
      />
      <input
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        placeholder="Адрес доставки"
        name="address"
        onChange={e => setAdress(e.target.value)}
      />
      <textarea
        className='flex relative w-full min-h-32 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        name="opisanie"
        placeholder="Описание проблемы"
        onChange={e => setOpisanie(e.target.value)}
      />
      <button className='btn-modal'
        onClick={() => handleSaveEng(
          phone,
          name,
          model,
          adress,
          opisanie
        )}
      >
        Вызвать инженера
      </button>
    </div>
  );
};

// Компонент формы для курьера
const CourierForm = ({ onSave, setIsSuccess, setIsLoading, setShowEngineerCourierModal }) => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [opisanie, setOpisanie] = useState('');
  const [adress, setAdress] = useState('');

  const todays = new Date();

  const today = todays.toLocaleDateString("ru-RU", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const handleSaveCur = async (phone, name, adress, opisanie) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`https://lk.eq74.ru/api/zayssitecur/newzayssitecur`, { type: 'courier', phone, name, model: 'none', adress, opisanie, data: today });
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        setShowEngineerCourierModal(false);
      }, 2000);
    } catch (error) {
      console.error('Error saving profile:', error);

    }
    setIsLoading(false);
  }

  // Здесь могут быть дополнительные состояния и логика для формы курьера
  return (
    <div className="flex relative flex-col w-full min-h-full bg-slate-50 gap-4 p-5 rounded-lg justify-center items-center content-center text-center">
      <input
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        placeholder="Имя"
        name="name"
        onChange={e => setName(e.target.value)}
      />
      <InputMask
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        mask="+7 (999) 999-99-99"
        maskChar="_"
        type="text"
        placeholder="Телефон"
        name="phone"
        value={phone}
        onChange={e => setPhone(e.target.value)}
      />
      <input
        className='flex relative w-full h-10 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        placeholder="Адрес"
        name="address"
        onChange={e => setAdress(e.target.value)}
      />
      <textarea
        className='flex relative w-full min-h-32 p-2 rounded-lg border-slate-900 border-2'
        type="text"
        name="opisanie"
        placeholder="Перечень передаваемой техники"
        onChange={e => setOpisanie(e.target.value)}
      />
      <button className='btn-modal'
        onClick={() => handleSaveCur(
          phone,
          name,
          adress,
          opisanie
        )}
      >
        Вызвать курьера
      </button>
    </div>
  );
};

export default Slider;
